import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const AdditionalSEOContentSupportHours = () => (
  <Layout>
    <SEO title="SEOptimized Blog " />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>Additional SEO Content Support Hours | $85 per hour</h2>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <p>
              SEO Content Support is an important part of any business's digital
              strategy. It helps brands optimize their web content for search
              engine rankings and visibility, enabling them to reach their
              target audience more effectively.<br></br>Content support hours
              could include anything from optimizing existing blogs or website
              content to keyword research and everything in between. If you want
              fresh blog content created, we suggest checking out our
              SEOptimized Blogs – available in a single serving or as a series
              of 3.<br></br>
              Ultimately, SEO Content Support can help businesses increase their
              online visibility and generate more leads, customers, and revenue.
            </p>
            <p></p>

            <p className="text-red">
              This can only be purchased as an add-on to one of our other SEO
              packages. Please return to the SEO packages page and select this
              add-on during the checkout process.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default AdditionalSEOContentSupportHours;
